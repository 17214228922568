import React, { Suspense, lazy, useRef, useState,useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import PhoneInput from 'react-phone-number-input'

import './styles.scss'

// Components import
import ConnectionStatus from 'presentation/components/component/connexionStatus/connectionStatus';

// Theme mode verification
import { checkmode } from "presentation/utils/theme/checkmode";

// Translate librairies
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'

// Redirection route importation
const Loader = lazy(() => import('presentation/pages/redirect/loader'));

// Principal route importation
const Main = lazy(() => import('./main'));
const Redirect = lazy(() => import('./redirect'));


// Initialize translation
i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
	debug: true,
	fallbackLng: 'fr',
	backend: {
		loadPath: "/locales/{{lng}}/translation.json",
	},
});

import { t } from 'presentation/utils/translation/i18nHelper'
import MyModal from 'presentation/components/component/modal/myModal';



/*
* @desc: Configuration des routes
*/
function RoutesConfig() {

	const [showSmallModal, setShowSmallModal] = useState(false);
	const [phoneValue, setPhoneValue] = useState();

	 // For small modal
    const openSmallModal = () => {
        setShowSmallModal(true);
    };

    const closeSmallModal = () => {
        setShowSmallModal(false);
		localStorage.setItem('newUser', 'false');
    };
	
	checkmode();
	const isDarkMode = localStorage.getItem('supermode') === 'true';
	const form = useRef();

	useEffect(() => {
		const newUser = localStorage.getItem('newUser');

		if (newUser === null) {
			localStorage.setItem('newUser', 'true');
			setShowSmallModal(true);
		} else {
			setShowSmallModal(newUser === 'true');
		}
		const interval = setInterval(() => {
			setShowSmallModal(true);
		}, 15 * 60 * 1000); // 15 minutes en millisecondes
	
		return () => clearInterval(interval);
	}, []);

	const sendEmail = (e) => {
		e.preventDefault();

		const loadingToast = toast.loading("Envoie du mail en cours...")
		emailjs
		.sendForm('service_e7px1jc', 'template_8n0f7q8', form.current, {
			publicKey: 'rEzblkvmiOqxbkeuR',
		})
		.then(
			() => {
				toast.update(loadingToast, { render: "Votre message a bien été envoyé", type: "success", isLoading: false });
				setTimeout(() => {
					toast.dismiss(loadingToast);
					window.location.reload();
				}, 5000);
				form.current.reset();
			setPhoneValue('');
			},
			(error) => {
				toast.update(loadingToast, { render: "Erreur lors de l'envoi du mail", type: "error", isLoading: false });
				setTimeout(() => {
				toast.dismiss(loadingToast);
			}, 5000);
	
			},
		);
	};
	

	return (
			<BrowserRouter>
				{/* Notification initialization */}
				<ToastContainer
					position="bottom-right"
					theme={isDarkMode ? 'dark' : 'light'}
					autoClose={5000}
					pauseOnHover={true}
					rtl={false}
					newestOnTop={false}  
					closeOnClick
					pauseOnFocusLoss
					draggable
				/>

				{/* Constant components */}
				<ConnectionStatus />

				<MyModal isOpen={showSmallModal} onClose={closeSmallModal} >
							<form ref={form} onSubmit={sendEmail} className='Modal-perm'>
								<h1>{t('modal.title2')}</h1>
								<p>{t('modal.para2')}</p>
								<input type="text" id="user_name" placeholder={t('contact.placeholder1')} name="user_name" required />
								<input type="text" id="user_name2" placeholder={t('contact.placeholder2')} name="user_name2" required />

								<input type="email" placeholder='Email'  id="email" name="user_email" required />

								
								<PhoneInput
										international
										// flags={flags}
										onlyCountries={['fr', 'ch', 'ae', 'be', 'ca']}
										defaultCountry="FR"
										name='user_phone'
										countryCallingCodeEditable={false}
										placeholder={t('contact.placeholder3')}
										value={phoneValue}
										onChange={setPhoneValue}
								/>

								<textarea id="message" name="user_message" placeholder='Votre Message içi' required></textarea>

								<input value={t('contact.btn1')} className="raise" type="submit"/>
							</form>
						</MyModal>

				<Suspense fallback={<Loader />}>
					
				
						{/* Pages Routes */}
						<Main />
						<Redirect />

				</Suspense>
			</BrowserRouter>
	)
}

export default RoutesConfig